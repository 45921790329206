import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Fade, Slide} from 'react-reveal';

// components
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import Article from '../components/article/article';
import TeamCard from "../components/team/team";
import ContactSection from "../components/contact/contact";

// icons
import icon_left from "../images/icons/icon-auktorisation.svg";
import icon_center from "../images/icons/icon-langsiktighet.svg";
import icon_right from "../images/icons/icon-kvalitet.svg";

// images
import img_text_section from "../images/vector/vector-excendo-tjanster.svg";


function About() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />

            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-omoss-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Om Excendo</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>


            <section>
                {/*-------------- card section - START ---------------*/}
                <div className="container mx-auto -mt-12 px-4 xl:px-24">
                    <Fade top cascade>
                        <div className="w-full flex flex-col md:flex-row justify-center lg:justify-between items-center" >
                            <div className="w-full md:w-4/12 pb-4 md:pr-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20" src={icon_left} alt="Auktorisation" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Auktorisation</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:px-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_center} alt="Långsiktighet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Långsiktighet</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:pl-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_right} alt="Kvalitet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Kvalitet</h2>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*-------------- card section - END ---------------*/}
            </section>


            <section>
                {/*-------------- text section - START ---------------*/}
                <Article
                    image={img_text_section}
                    image_alt=""
                    heading="Excendo Redovisning"
                    title="Vilka vi är"
                >
                    <p>
                        Excendo är en redovisningsbyrå som jobbar även med enskilda uppdrag
                        såsom bolagsköp och försäljningar, fusioner, likvidationer och vid mer
                        komplicerade skattefrågor där vi även vid behov kopplar in personer i
                        vårt nätverk av revisorer, skattejurister och advokater specialiserade i bolagsfrågor.
                        <br /><br />
                        Vi agerar även som ombud för utlandsägda bolag samt ombud för
                        moms och arbetsgivaravgifter som går att läsa mer om under fliken delgivningsmottagare.
                        <br /><br />
                        Vi lägger stor vikt vid att hålla en förstklassig service och en genomgående hög
                        kvalité på levererade tjänster samtidigt som vår
                        målsättning är att fullgöra våra uppdrag på ett kostnadseffektivt sätt.
                        <br /><br />
                        Kontakta oss för mer information.
                    </p>
                </Article>
                {/*-------------- text section - END ---------------*/}
            </section>


            <div className="container mx-auto px-4 xl:px-24">
                <hr className="border-1 border-lightgray-excendo"></hr>
            </div>



            <section>
                {/*-------------- team section - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24 my-32">
                    <div className="w-full">
                        <div className="w-full lg:w-7/12 2xl:w-5/12 mx-auto flex flex-col items-center justify-center">
                            <p className="text-center">
                                <span className="text-blue-excendo font-semibold py-1 px-4 rounded-full shadow-md">Vårt Team</span>
                            </p>
                            <h2 className="text-3xl lg:text-4xl font-semibold text-black-excendo text-center mt-10 filter drop-shadow-md">Träffa vårt team</h2>
                            <p className="text-center text-gray-excendo mb-10 mt-4 lg:mt-8 xl:mt-10">Du är alltid varmt välkommen att kontakta oss!</p>
                        </div>
                    </div>
                    
                    <Slide bottom>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <TeamCard
                                name="Melike Ayranci"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-121 57 646"
                                email="melike.ayranci@excendo.se"
                            />
                            <TeamCard
                                name="Helena Blomqvist"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-121 32 471"
                                email="helena@excendo.se"
                            />
                            <TeamCard
                                name="Amanda Chamorro"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-121 56 789"
                                email="amanda.chamorro@excendo.se"
                            />
                            <TeamCard
                                name="Martin Ekeroth"
                                gender="male"
                                position="Auktoriserad Redovisningskonsult"
                                phone="08-121 32 470"
                                email="martin@excendo.se"
                            />
                            <TeamCard
                                name="Sandra Fuentes Delgado"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-121 04 744"
                                email="sandra@excendo.se"
                            />
                            <TeamCard
                                name="Magnus Lhådö"
                                gender="male"
                                position="Auktoriserad Redovisningskonsult"
                                phone="08-121 04 745"
                                email="magnus@excendo.se"
                            />
                            <TeamCard
                                name="Natalie Oyal"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-122 09 489"
                                email="natalie@excendo.se"
                            />
                            <TeamCard
                                name="Erica Schönberg"
                                gender="female"
                                position="Redovisningskonsult"
                                phone="08-121 32 472"
                                email="erica@excendo.se"
                            />
                            <TeamCard
                                name="Josefin Svensson"
                                gender="female"
                                position="Auktoriserad Redovisningskonsult"
                                phone="08-121 32 473"
                                email="josefin@excendo.se"
                            />
                            <TeamCard
                                name="Hadir Tawfik"
                                gender="male"
                                position="Redovisningskonsult"
                                phone="08-121 54 647"
                                email="hadir@excendo.se"
                            />
                            <TeamCard
                                name="Martin Thunstam"
                                gender="male"
                                position="Redovisningskonsult"
                                phone="08-121 047 46"
                                email="martin.thunstam@excendo.se"
                            />
                        </div>
                    </Slide>

                </div>
                {/*-------------- team section - START ---------------*/}
            </section>

            <ContactSection />
            <Footer />
        </Layout>
    );
}

export default About;