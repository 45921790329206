import React from "react";

function TeamCard(props) {
    return(
        <div className="w-full">
            <div className="team_card_bg_color rounded-lg flex flex-col justify-evenly p-4 mx-2 mb-14">
                {props.gender === "male" ?
                    <div className="mx-auto bg-blue-100 rounded-full w-16 h-16 flex justify-center items-center text-blue-excendo shadow-2xl">
                        <svg className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor" >
                            <path d="M9.3607+0.329158C8.59374+0.468296+7.71567+0.972796+6.98855+1.69611C6.22159+2.45506+5.80838+3.26967+5.67325+4.26706C5.6216+4.66846+5.64542+5.06966+5.75673+5.54253C5.80037+5.72531+5.83621+5.88026+5.83621+5.88827C5.83621+5.89628+5.77655+5.9161+5.70908+5.92811C5.24422+6.02741+5.19658+6.65523+5.56614+7.81558C5.63361+8.02218+5.73311+8.28044+5.78476+8.39175C5.89607+8.61037+6.10267+8.8488+6.18215+8.8488C6.20998+8.8488+6.24181+8.89645+6.25763+8.95611C6.49206+10.0528+6.75432+10.6608+7.23119+11.233C7.95831+12.1072+9.23378+12.6157+10.3267+12.4728C11.9637+12.2542+13.0406+11.1775+13.446+9.3535C13.5056+9.07923+13.5214+9.0476+13.5929+9.07142C13.8592+9.15491+14.1214+8.79335+14.3797+7.99456C14.6816+7.04101+14.6976+6.40918+14.4155+6.21039L14.324+6.14672L14.3917+5.74132C14.666+4.11211+14.5348+2.95977+14.0103+2.29631C13.8433+2.0857+13.5533+1.87909+13.3388+1.81543C13.2037+1.7796+13.1402+1.72794+13.0209+1.55717C12.6315+1.00082+11.7533+0.484311+10.9269+0.329358C10.5209+0.253682+9.78572+0.249678+9.3607+0.329158Z" opacity="1" fill="currentColor"/>
                            <path d="M6.45202+12.2736C5.9275+12.5993+4.8228+13.1477+4.24663+13.3703C4.01621+13.4578+3.55134+13.6167+3.21361+13.716C2.48649+13.9346+2.26387+14.0538+1.96597+14.3757C1.61242+14.7533+1.3898+15.3016+1.31032+15.9809L1.27449+16.2988L1.49691+16.5094C3.4082+18.3016+5.6254+19.3782+8.23999+19.7836C8.99094+19.8988+10.5407+19.9266+11.3194+19.8393C12.7737+19.6725+14.0612+19.3068+15.3208+18.703C16.5168+18.1308+17.3951+17.5348+18.4201+16.6049C18.73+16.3228+18.742+16.3068+18.726+16.1679C18.5949+15.1627+18.3842+14.658+17.9194+14.2446C17.6531+14.0021+17.4109+13.891+16.8147+13.72C15.6902+13.3981+14.5935+12.9054+13.5166+12.242L13.1114+11.9917L13.0716+12.1188C11.7563+16.295+11.3034+17.7096+11.2398+17.8209C11.2239+17.8527+11.061+17.4355+10.8504+16.8355L10.4848+15.7985L10.5801+15.6714C10.898+15.2381+11.1999+14.5388+11.1999+14.2249C11.1999+13.8077+10.9575+13.5216+10.5046+13.3985C10.1233+13.2952+9.57091+13.331+9.27282+13.478C8.63699+13.7879+8.67282+14.5509+9.36811+15.5801L9.52306+15.8105L9.43177+16.0569C9.38413+16.1881+9.22116+16.6529+9.07021+17.0822C8.91927+17.5152+8.78814+17.8689+8.77612+17.8689C8.76411+17.8689+8.36291+16.6291+7.88604+15.1154C7.40516+13.6055+6.98795+12.2822+6.95232+12.1829L6.88865+12.0001L6.45202+12.2736Z" opacity="1" fill="currentColor"/>
                        </svg>
                    </div>
                :
                    <div className="mx-auto bg-pink-100 rounded-full w-16 h-16 flex justify-center items-center text-pink-500 shadow-2xl">
                        <svg className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor" >
                            <path d="M9.21835+0.204103C8.82368+0.276519+8.27212+0.473851+7.8857+0.671184C6.78257+1.24287+6.1262+2.37436+5.89668+4.10972C5.85645+4.42774+5.82829+5.02376+5.81622+5.84106C5.80415+6.64225+5.77599+7.25839+5.73978+7.5563C5.55854+8.88895+5.12787+9.77081+4.50771+10.0567L4.31038+10.1492L4.419+10.2659C4.5922+10.4511+5.07135+10.749+5.41753+10.886C6.05781+11.1397+6.61742+11.2523+7.42666+11.2925L7.9903+11.3167L8.01041+12.0213L8.03455+12.726L9.0532+16.2611C9.61281+18.2058+10.0638+19.8042+10.0558+19.8122C10.0437+19.8243+7.44276+16.0597+7.20117+15.6771C7.18508+15.653+6.88314+15.6811+6.42411+15.7495C6.01335+15.8099+5.66717+15.8501+5.6551+15.838C5.64303+15.83+5.92484+15.0972+6.27928+14.2153C6.63351+13.3294+6.92358+12.6007+6.92358+12.5966C6.92358+12.5886+6.823+12.6489+6.69808+12.7296C6.40017+12.9189+6.04574+13.0677+5.3936+13.2691C4.3146+13.6032+3.87568+13.7603+3.39251+13.9938C2.79669+14.2837+2.52674+14.5293+2.31754+14.9763C2.12423+15.375+2.09205+15.5924+2.09205+16.3816L2.09205+17.0741L2.47464+17.3559C3.85557+18.3706+5.42598+19.1034+7.08893+19.5101C8.19206+19.7799+9.97992+19.9651+10.0966+19.8201C10.1167+19.7959+10.5999+18.278+11.1635+16.4499C11.7312+14.6221+12.2304+13.0114+12.2749+12.8666C12.3433+12.6491+12.3594+12.4961+12.3594+11.9566L12.3594+11.3084L12.8144+11.2762C13.9419+11.2079+14.9806+10.9258+15.5724+10.5274C15.7778+10.3865+15.9832+10.2093+15.9832+10.1691C15.9832+10.157+15.9188+10.1128+15.8381+10.0766C15.6046+9.96372+15.3831+9.76659+15.1938+9.49684C14.6785+8.78012+14.5133+7.93869+14.6179+6.56159C14.767+4.59671+14.6139+3.2882+14.1227+2.2776C13.9535+1.92337+13.869+1.80248+13.6114+1.54882C13.1523+1.08979+12.7094+0.908547+12.0572+0.908547C11.7634+0.908547+11.7553+0.908547+11.4936+0.707191C11.1675+0.461581+11.0145+0.381119+10.6482+0.264249C10.2814+0.151803+9.63313+0.123641+9.21835+0.204103Z" opacity="1" fill="currentColor"/>
                            <path d="M13.3012+12.6495C13.3132+12.6938+13.6031+13.4226+13.9455+14.2763C14.3482+15.2828+14.5495+15.8304+14.5172+15.8384C14.489+15.8425+14.1629+15.8022+13.7964+15.7459C13.4259+15.6896+13.0958+15.6453+13.0676+15.6453C13.0274+15.6453+10.374+19.4703+10.1646+19.8287C10.1364+19.877+10.2129+19.881+10.6518+19.8569C11.8758+19.7885+12.9105+19.5992+14.03+19.2408C15.2902+18.838+16.6593+18.1376+17.698+17.3684L18.1168+17.0544L18.1168+16.3739C18.1168+15.5848+18.0806+15.3714+17.8873+14.9727C17.6779+14.5378+17.3518+14.2479+16.7801+13.9782C16.2487+13.7285+15.8339+13.5714+15.1373+13.3703C14.2715+13.1206+13.7846+12.9233+13.4623+12.7018C13.3132+12.6013+13.2811+12.5892+13.3012+12.6495Z" opacity="1" fill="currentColor"/>
                        </svg>
                    </div>
                }
                
                <h2 className="mt-6 text-black-excendo font-medium text-xl text-center">{props.name}</h2>
                <p className="text-center my-6">
                    <span className="bg-white text-black-excendo py-1 px-4 rounded-full shadow-md">{props.position}</span>
                </p>
                <p className="opacity-80 text-center text-blue-excendo">{props.phone}</p>
                <p className="opacity-80 text-center text-blue-excendo">{props.email}</p>
            </div>
        </div>
    );
}
export default TeamCard;